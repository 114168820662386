import React, { forwardRef, useEffect } from 'react';
import MaterialTable from 'material-table';
import styled from 'styled-components';

import {
  EditIcon,
  SortDownIcon,
  LastPageIcon,
  FirstPageIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  SearchIcon,
  ViewIcon,
} from '../../../components/atoms/Icons';

import { Job, JobPost, JobPostStatus } from '../../../generated/types';
import { ClearIcon } from '../../../components/atoms/Icons/ClearIcon';
import { ErrorBoundary } from '../../../components/molecules/ErrorBoundary';
import {
  JobPostTableStatus,
  JobTableCompany,
  JobTablePosition,
  JobTableRate,
} from '../../../components/molecules/JobTable/JobTable';
import { PrimaryButton } from '../../../components/atoms/Button';

export const JobsOffersViewTable: React.FC<JobsViewTableProps> = ({
  handleViewClick,
  handleEditClick,
  handleJobPostPublish,
  handleJobPostUnpublish,
  jobPosts,
}: JobsViewTableProps) => {
  // https://stackoverflow.com/questions/59648434/material-table-typeerror-cannot-add-property-tabledata-object-is-not-extensibl
  // Bug so we need to clone the data
  const clonedData = jobPosts.map((jobPost) => ({ ...jobPost }));

  return (
    <ErrorBoundary>
      <MaterialTable
        icons={tableIcons}
        columns={[
          {
            title: 'Company',
            field: 'company',
            render: (jobPost) => {
              return <JobTableCompany company={jobPost.company} />;
            },
          },
          {
            title: 'Position',
            field: 'jobTitle',
            render: (job) => {
              return (
                <JobTablePosition
                  onClick={() => {
                    handleViewClick(job?.userUuid, job?.uuid);
                  }}
                  jobTitle={job.jobTitle}
                  jobTitleUrl={job.jobUrl}
                />
              );
            },
          },
          {
            title: 'Rate',
            field: 'rate',
            render: (job) => <JobTableRate ir35={job.ir35} rate={job.rate} duration={job.duration}></JobTableRate>,
          },
          {
            title: 'Status',
            field: 'rate',
            render: (job) => <JobPostTableStatus status={job.status}></JobPostTableStatus>,
          },
          {
            title: 'Worklow',
            field: 'workflow',
            render: (job) => {
              const isPublished = job.status === JobPostStatus.Published;
              return (
                <PrimaryButton
                  inline={true}
                  size="small"
                  onClick={() => {
                    if (job) {
                      if (isPublished) {
                        handleJobPostUnpublish(job.userUuid, job.uuid);
                      } else {
                        handleJobPostPublish(job.userUuid, job.uuid);
                      }
                    }
                  }}
                >
                  {isPublished ? 'Unpublish' : 'Publish'}
                </PrimaryButton>
              );
            },
          },
        ]}
        data={clonedData}
        title="Your Job Offers Posts"
        actions={[
          {
            icon: () => <ViewIcon />,
            tooltip: 'View Job',
            onClick: (event, rowData) => {
              event.preventDefault();
              handleViewClick((rowData as JobPost)?.userUuid, (rowData as JobPost)?.uuid);
            },
          },
          ...(handleEditClick
            ? [
                {
                  icon: () => <EditIcon size={1.2} />,
                  tooltip: 'Edit Job',
                  onClick: (event: any, rowData: any) => {
                    event.preventDefault();
                    handleEditClick((rowData as JobPost)?.userUuid, (rowData as JobPost)?.uuid);
                  },
                },
              ]
            : []),
        ]}
        options={{
          sorting: false,
          filtering: false,
          search: false,
        }}
      />
    </ErrorBoundary>
  );
};

const tableIcons: any = {
  FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <SortDownIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeftIcon {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
};

interface JobsViewTableProps {
  jobPosts: JobPost[];
  handleViewClick: (userUuid: string, jobUuid: string) => void;
  handleEditClick?: (userUuid: string, jobUuid: string) => void;
  handleDeleteClick?: (juserUuid: string, obUuid: string) => void;
  handleJobPostPublish: (userUuid: string, jobUuid: string) => void;
  handleJobPostUnpublish: (userUuid: string, jobUuid: string) => void;
}

export default JobsOffersViewTable;
